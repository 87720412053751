<template>
  <div class="tile is-6 is-vertical">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>