<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-2-desktop is-6-mobile" v-for="(lang, index) in langs" :key="index">
      <div class="card">
        <div style="padding: 1rem;" class="card-image">
          <figure class="image is-2by3">
            <img
              style="width: auto; height: auto; margin: auto"
              :src="lang.logo_path | image"
              alt="Placeholder image"
            />
          </figure>
        </div>
        <div class="card-content">
          <p class="title is-size-6-desktop is-size-7-mobile">{{ getName(lang.iso_639_1) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countrylist from "country-list";
window.countrylist = countrylist;
export default {
  name: "spokenlangs    ",
  props: { langs: Array },
  methods: {
    getName(code) {
      return countrylist.getName(code);
    }
  },
  created() {
    var x = countrylist.getName("TW");
    console.log(x);
  },
  filters: {
    image(url) {
      return url != null
        ? "https://image.tmdb.org/t/p/w500/" + url
        : "https://lengthapi.win/movies/images/unknown.png";
    }
  }
};
</script>