<template>
  <div class="image-container">
    <router-link :to="{ name: to, params: {id: id } }">
      <img :src="image(path)" />
    </router-link>
    <div class="image-caption">
      <p>{{ caption }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "captionedimage",
  props: ["caption", "path", "to", "id"],
  data() {
    return {
      notfound: require("@/assets/images/personnotfound.png")
    };
  },
  methods: {
    image(url) {
      return url != null
        ? "https://image.tmdb.org/t/p/w500/" + url
        : this.notfound;
    }
  }
};
</script>

<style scoped>
img {
  height: 15rem;
  max-width: 15rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.image-container {
  position: relative;
  display: inline-flex;
}

.image-caption {
  position: absolute;
  bottom: 1em;
  padding-left: 0.3em;
  padding-right: 0.3em;
  margin-left: 2em;
  font-size: 60%;
  font-weight: 600;
  max-width: 80%;
  white-space: normal;
  word-wrap: break-word;
  background-color: black;
  color: white;
}
</style>