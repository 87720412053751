<template>
  <div>
    <scrollmenu :showArrows="true">
      <div v-for="(video, index) in videos" :key="index" class="box trailer-box">
        <img
          @click.prevent="clickedOnTrailer(video.key, video.site)"
          style="width: 220px;"
          :src="'https://img.youtube.com/vi/' + video.key + '/0.jpg'"
        />
        <p class="title is-7 formatted">{{ video.name }}</p>
        <p class="subtitle is-7" style="font-variant: small-caps">{{ video.site }}</p>
      </div>
    </scrollmenu>
    <trailer v-if="showTrailer" @closeTrailer="showTrailer = false" :videokey="currentkey"></trailer>
  </div>
</template>

<script>
import Scrollmenu from "@/components/Sidescroll/Scrollmenu.vue";
import Trailer from "@/components/Trailer.vue";

export default {
  name: "trailers",
  components: {
    Scrollmenu,
    Trailer
  },
  data() {
    return {
      currentkey: "",
      showTrailer: false
    };
  },
  methods: {
    clickedOnTrailer(key, site) {
      if (site == "YouTube") {
        this.currentkey = key;
        this.showTrailer = true;
      } else if (site == "Vimeo") {
        window.location.href = "https://vimeo.com/" + key;
      }
    }
  },
  props: ["videos"]
};

var x = [
  {
    id: "5d5446ef55c92600162572e3",
    iso_639_1: "en",
    iso_3166_1: "US",
    key: "isOGD_7hNIY",
    name: "Parasite [Official Trailer] – In Theaters October 11, 2019",
    site: "YouTube",
    size: 1080,
    type: "Trailer"
  },
  {
    id: "5dfdc9799824c80017df2566",
    iso_639_1: "en",
    iso_3166_1: "US",
    key: "PhPROyE0OaM",
    name: "Parasite [Trailer 2] – Now Playing in New York & Los Angeles.",
    site: "YouTube",
    size: 1080,
    type: "Trailer"
  },
  {
    id: "5dfdc9998c7b0f0018fe2ef7",
    iso_639_1: "en",
    iso_3166_1: "US",
    key: "CEIwFAQ-Rec",
    name: "PARASITE Official Int'l Teaser Trailer",
    site: "YouTube",
    size: 1080,
    type: "Teaser"
  }
];
</script>

<style scoped>
.trailer-box {
  margin: 10px;
  margin-right: 2rem;
  height: fit-content;
}

.formatted {
  white-space: normal;
  max-width: 224px;
}
</style>