<template>
  <div class="column is-5-desktop is-7-tablet is-12-mobile" style="overflow-y: hidden">
    <article class="media box" style="overflow-y: hidden">
      <div class="media-content">
        <div class="content">
          <div>
            <strong class="title is-6">{{ review.author }}</strong>

            <div>
              <p class="lines" :class="{ 'linetrimmer': isNotExpanded }">{{ review.content }}</p>
              <a
                style="float: right;"
                @click.prevent="isNotExpanded ? isNotExpanded = false : isNotExpanded = true"
              >{{ expandMsg }}</a>
            </div>
            <div class="has-text-centered" style="margin-top: 2rem">
              <a :href="review.url" class="button">
                <img style="padding-top: 0.15rem; padding-bottom: 0.15rem; height: 2rem" src="@/assets/images/tmdb-logos/tmdb-stacked-green.svg" />

                <p style="padding-left: 10px;">View on TMDb</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  props: ["review"],
  data() {
    return {
      isNotExpanded: true,
      expandMsg: "more"
    };
  },
  watch: {
    isNotExpanded() {
      this.isNotExpanded
        ? (this.expandMsg = "more")
        : (this.expandMsg = "less");
    }
  }
};
</script>

<style scoped>
.lines {
  padding-top: 0.5rem !important;
  margin-bottom: 0 !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal;
}

.media {
  display: -webkit-box !important;
}

.linetrimmer {
  -webkit-line-clamp: 5;
}
</style>