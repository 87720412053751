<template>
  <div class="trailer-overlay">
    <div class="closeIcon">
      <font-awesome-icon @click="$emit('close-trailer')" class="fa-2x" icon="times"></font-awesome-icon>
    </div>
    <iframe
      v-if="videokey != '' || videokey != undefined"
      width="1120"
      height="630"
      :src="'https://www.youtube-nocookie.com/embed/' + videokey + '?autoplay=1&rel=0&showinfo=0'"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "trailer",
  props: ["videokey"]
};
</script>

<style scoped>
.trailer-overlay {
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 333;
}

.closeIcon {
  position: absolute;
  top: 2rem;
  left: 2rem;
  color: red;
}

.closeIcon:hover {
  transition: 150ms ease-in color;
  color: #4d4d4d;
}
</style>