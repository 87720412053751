<template>
  <div class="column is-narrow">
    <img :src="image" class="poster" />
  </div>
</template>

<script>
export default {
  props: ["imageurl"],
  data() {
    return { notfound: require("@/assets/images/unknown.png") };
  },
  computed: {
    image() {
      let url = this.imageurl;
      return url != null
        ? "https://image.tmdb.org/t/p/w500/" + url
        : this.notfound;
    }
  }
};
</script>

<style scoped>
.poster {
  max-width: 10.5rem;
  max-height: 10.5rem;
  border-radius: 5px;
}
</style>