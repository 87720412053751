<template>
  <div class="tile is-ancestor box is-vertical is-parent" style="margin-top: 2rem;" v-if="dates.length > 0">
    <div :class="{ hidden: isClosed }">
      <div class="tile is-parent is-12">
        <div class="tile is-12 is-child">
          <p class="title is-6">Release Dates</p>
        </div>
      </div>
      <div class="tile is-parent is-12">
        <div class="tile is-6 is-child" style="padding-bottom: 1.75rem">
          <div class="media" style="border: none;" v-for="(country, index) in dates1" :key="index">
            <div class="media-left">
              <img
                style="height: 5rem; width: 5rem;"
                :src="verifyImageExists(country.iso_3166_1, index, 'dates1')"
              />
            </div>
            <div class="media-content" style="margin-top: 1rem">
              <p class="title is-5">{{ getName(country.iso_3166_1) }}</p>
              <div
                v-for="(date, index) in country.release_dates"
                :key="index"
                class="subtitle is-6 info"
              >
                <p>{{ getReleaseType(date.type) }}</p>
                <p>{{ formatDate(date.release_date) }}</p>
                <p class="boxed">{{ date.certification }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-6 is-child">
          <div class="media" style="border: none;" v-for="(country, index) in dates2" :key="index">
            <div class="media-left">
              <img
                style="height: 5rem; width: 5rem;"
                :src="verifyImageExists(country.iso_3166_1, index, 'dates2')"
              />
            </div>
            <div class="media-content" style="margin-top: 1rem">
              <p class="title is-5">{{ getName(country.iso_3166_1) }}</p>
              <div
                v-for="(date, index) in country.release_dates"
                :key="index"
                class="subtitle is-6 info"
              >
                <p>{{ getReleaseType(date.type) }}</p>
                <p>{{ formatDate(date.release_date) }}</p>
                <p class="boxed">{{ date.certification }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style="margin-top: 2rem" />
      <p style="margin-top: 2rem; margin-left: 1rem; font-size: 70%">
        These flag icons were made by
        <a href="https://www.flaticon.com/authors/freepik">Freepik</a> from
        <a href="https://www.flaticon.com">www.flaticon.com</a>
      </p>
    </div>

    <div style="margin-right: 1rem; margin-bottom: 2rem;">
      <a style="float: right;" @click="handleClicks()">{{ expandmsg }}</a>
    </div>
  </div>
</template>

<script>
import Detailparent from "@/components/movies/Detailparent.vue";
import countrylist from "country-list";
import moment from "moment";

export default {
  name: "releasedates",
  components: {
    Detailparent
  },
  props: ["dates"],
  data() {
    return {
      dates1: [],
      dates2: [],
      isClosed: true,
      expandmsg: "more"
    };
  },
  watch: {
    dates() {
      this.setupDatesBox();
    }
  },
  mounted() {
    this.setupDatesBox();
  },
  methods: {
    setupDatesBox() {
      this.isClosed = true;
      this.expandmsg = "more";
      if (this.dates.length <= 4) {
        this.isClosed = false;
        this.expandmsg = "";
      }
      this.sortDates();
    },
    sortDates() {
      let halfwayThrough = Math.ceil(this.dates.length / 2);
      this.dates1 = _.orderBy(
        this.dates,
        [country => this.getName(country.iso_3166_1)],
        ["asc"]
      ).slice(0, halfwayThrough);
      this.dates2 = _.orderBy(
        this.dates,
        [country => this.getName(country.iso_3166_1)],
        ["asc"]
      ).slice(halfwayThrough, this.dates.length);
    },
    getName(code) {
      return countrylist.getName(code);
    },
    getReleaseType(type) {
      let types = [
        "Premiere",
        "Theatrical (limited)",
        "Theatrical",
        "Digital",
        "Physical",
        "TV"
      ];

      return types[type - 1];
    },
    formatDate(date) {
      return moment(date).format("D MMM YYYY");
    },
    handleClicks() {
      if (this.isClosed == false) {
        this.isClosed = true;
        this.expandmsg = "more";
      } else {
        this.isClosed = false;
        this.expandmsg = "less";
      }
    },
    verifyImageExists(img, index, whichlist) {
      try {
        return require("@/assets/images/flags/" + img + ".svg");
      } catch {
        whichlist == "dates1"
          ? this.dates1.splice(index, 1)
          : this.dates2.splice(index, 1);
      }
    }
  }
};
</script>

<style scoped>
.info p {
  display: inline-block;
  margin-right: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.info p:empty {
  display: none;
}

.boxed {
  text-align: center;
  padding-left: 1px;
  padding-right: 1px;
  border: 1.5px solid #4d4d4d;
  border-radius: 2px;
}

.hidden {
  height: 20rem;
  overflow: hidden;
  -webkit-mask: -webkit-gradient(
    linear,
    left center,
    left bottom,
    from(black),
    to(transparent)
  );
  mask: -webkit-gradient(
    linear,
    left center,
    left bottom,
    from(black),
    to(transparent)
  );
}
</style>